// src/pages/AboutPage.jsx
import React from 'react';
import { Container, Typography } from '@mui/material';
import Hero from '../components/Hero';

function AboutPage() {
  return (
    <div>
      <Hero
        title="About CoHost"
        subtitle="A visionary approach to event attendance—simple, scalable, and attendee-first"
      />
      <Container sx={{ mt:8, textAlign:'center' }}>
        <Typography variant="h4" gutterBottom>Our Philosophy</Typography>
        <Typography variant="body1" paragraph color="text.secondary" sx={{maxWidth:700,mx:'auto'}}>
          CoHost envisions a world where attending events is as natural as meeting friends. No convoluted steps—just a clear path from discovering an event to enjoying it.
        </Typography>
        <Typography variant="body1" paragraph color="text.secondary" sx={{maxWidth:700,mx:'auto'}}>
          We focus on building trust through consistency and transparency. As you engage with events, CoHost quietly supports your journey, offering you a chance to grow into a host role if you choose, without overwhelming details.
        </Typography>
        <Typography variant="body1" paragraph color="text.secondary" sx={{maxWidth:700,mx:'auto'}}>
          Our public-facing persona invites you to explore, attend, and evolve at your own pace. Behind the scenes, we continuously refine tools and insights—but for now, enjoy a platform that embraces simplicity, letting you savor events without barriers.
        </Typography>
      </Container>
     
    </div>
  );
}

export default AboutPage;
