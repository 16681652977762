// src/components/Hero.jsx
import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const heroImageUrl = 'https://images.unsplash.com/photo-1501386761578-eac5c94b800a?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

function Hero({ title, subtitle }) {
  return (
    <Box
      sx={{
        position: 'relative',
        height: { xs: '400px', md: '600px' },
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        color:'#fff',
        backgroundImage:`url(${heroImageUrl})`,
        backgroundSize:'cover',
        backgroundPosition:'center',
        '&:before': {
          content:'""',
          position:'absolute',
          top:0,left:0,right:0,bottom:0,
          background:'linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.3), rgba(0,0,0,0.6))',
          zIndex:1,
        },
      }}
    >
      <Container sx={{ position:'relative', zIndex:2, textAlign:'center' }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{ fontWeight:'bold', fontSize:{xs:'2.5rem', md:'3.5rem'} }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="h6"
            component="p"
            gutterBottom
            sx={{ maxWidth:600, mx:'auto', mb:4 }}
          >
            {subtitle}
          </Typography>
        )}
      </Container>
    </Box>
  );
}

export default Hero;
