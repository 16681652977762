// src/pages/PricingPage.jsx
import React from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Hero from '../components/Hero';

function PricingPage() {
  // Non-unique, generic features to compare:
  const features = [
    { name: 'Basic Event Browsing', attendee: true, privateHost: true, premiumHost: true },
    { name: 'Basic RSVP and Check-in', attendee: true, privateHost: true, premiumHost: true },
    { name: 'Email Notifications', attendee: true, privateHost: true, premiumHost: true },
    { name: 'Calendar Integration', attendee: true, privateHost: true, premiumHost: true },
    { name: 'Unlimited Invites', attendee: false, privateHost: true, premiumHost: true },
    { name: 'Advanced Analytics (attendee counts, trends)', attendee: false, privateHost: false, premiumHost: true },
    { name: 'Promoter Dashboards', attendee: false, privateHost: false, premiumHost: true },
    { name: 'Custom Tagging & Segmentation', attendee: false, privateHost: false, premiumHost: true },
    { name: 'Priority Support', attendee: false, privateHost: false, premiumHost: true }
  ];

  return (
    <div>
      <Hero
        title="Pricing"
        subtitle="Flexible tiers that evolve as your event interests grow"
      />
      <Container sx={{ mt:8, textAlign:'center' }}>
        <Typography variant="h4" gutterBottom>Compare Our Plans</Typography>
        <Typography variant="body1" paragraph color="text.secondary" sx={{ maxWidth:700, mx:'auto' }}>
          Start free as an attendee or private host with familiar event platform features. Upgrade to premium host for advanced capabilities when you need them.
        </Typography>
        <Box sx={{ overflowX:'auto', mt:4 }}>
          <Table sx={{ mx:'auto', minWidth:650, maxWidth:900 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center" sx={{fontWeight:'bold'}}>Attendee (Free)</TableCell>
                <TableCell align="center" sx={{fontWeight:'bold'}}>Private Host (Free)</TableCell>
                <TableCell align="center" sx={{fontWeight:'bold'}}>Premium Host (Paid)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {features.map((feat, i) => (
                <TableRow key={i}>
                  <TableCell align="left" sx={{fontWeight:'bold', color:'text.secondary'}}>{feat.name}</TableCell>
                  <TableCell align="center">{feat.attendee ? '✔️' : '❌'}</TableCell>
                  <TableCell align="center">{feat.privateHost ? '✔️' : '❌'}</TableCell>
                  <TableCell align="center">{feat.premiumHost ? '✔️' : '❌'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Grid container spacing={4} sx={{ mt:6, justifyContent:'center' }}>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent sx={{textAlign:'left'}}>
                <Typography variant="h5" gutterBottom>Attendee</Typography>
                <Typography variant="body2" paragraph>
                  Perfect for those who just want to attend events without any complexity. Enjoy features commonly found on other platforms.
                </Typography>
                <Button variant="contained" color="primary" href="/signup">Join as Attendee</Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent sx={{textAlign:'left'}}>
                <Typography variant="h5" gutterBottom>Private Host</Typography>
                <Typography variant="body2" paragraph>
                  Host small events with limited invites. Ideal for testing the waters before committing. Request host approval for more tools later.
                </Typography>
                <Button variant="contained" color="primary" href="/signup">Try Private Host</Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent sx={{textAlign:'left'}}>
                <Typography variant="h5" gutterBottom>Premium Host</Typography>
                <Typography variant="body2" paragraph>
                  Unlock advanced analytics, promoter dashboards, and custom tagging. Perfect for organizers looking to elevate their events beyond basics.
                </Typography>
                <Button variant="outlined" color="primary" href="/signup">Upgrade to Premium</Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="body1" paragraph mt={4} color="text.secondary" sx={{maxWidth:700,mx:'auto'}}>
          These tiers mirror familiar event management features found across the industry. Start simple and upgrade only when you need advanced insights or promoter-level tools. No complexity upfront—just the capacity to grow as your event aspirations do.
        </Typography>
      </Container>
    </div>
  );
}

export default PricingPage;
