// src/pages/FeaturesPage.jsx
import React from 'react';
import { Container, Typography } from '@mui/material';
import Hero from '../components/Hero';

function FeaturesPage() {
  return (
    <div>
      <Hero
        title="Features"
        subtitle="Discover familiar, attendee-friendly features and consider growing later"
      />
      <Container sx={{ mt:8, textAlign:'center' }}>
        <Typography variant="h4" gutterBottom>Familiar Tools</Typography>
        <Typography variant="body1" paragraph color="text.secondary" sx={{maxWidth:700,mx:'auto'}}>
          CoHost starts with features you already know from other event platforms:
        </Typography>
        <Typography variant="body1" paragraph color="text.secondary" sx={{maxWidth:700,mx:'auto', textAlign:'left'}}>
          - Event Listings: Browse upcoming events easily, just like many online calendars.<br/>
          - Basic Registration: Sign up without complex steps, similar to other registration forms you’ve used.<br/>
          - Standard Calendar View: A simple monthly or weekly layout helps you plan, no unique tricks needed.<br/>
        </Typography>
        <Typography variant="body1" paragraph color="text.secondary" sx={{maxWidth:700,mx:'auto'}}>
          Initially, CoHost offers what you expect—no surprises. As you get comfortable, you might discover more advanced options once you become a host. But for now, enjoy a platform that feels familiar, stable, and easy to trust.
        </Typography>
      </Container>
    </div>
  );
}

export default FeaturesPage;
