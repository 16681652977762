// src/pages/LoginPage.jsx
import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, IconButton, InputAdornment, Link as MuiLink } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import API from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ email:'', password:'' });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const onChange = (e) => setFormData({...formData,[e.target.name]:e.target.value});

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await API.post('/auth/login', formData);
      console.log('Login success:', res.data);
      login(res.data.user, res.data.token);
      navigate('/dashboard');
    } catch(err) {
      console.log('Login error response:', err.response?.data);
      let errMsg = err.response?.data?.error || err.response?.data?.message || 'Login failed. Check your credentials.';
      setError(errMsg);
    }
  };

  return (
    <Container sx={{mt:8,maxWidth:'sm'}}>
      <Typography variant="h4" gutterBottom>Login</Typography>
      {error && <Typography color="error" paragraph>{error}</Typography>}
      <Box component="form" onSubmit={onSubmit} sx={{maxWidth:400,mx:'auto'}}>
        <TextField
          name="email"
          label="Email"
          type="email"
          fullWidth
          sx={{mb:2}}
          value={formData.email}
          onChange={onChange}
          required
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword?'text':'password'}
          fullWidth
          sx={{mb:2}}
          value={formData.password}
          onChange={onChange}
          required
          InputProps={{
            endAdornment:(
              <InputAdornment position="end">
                <IconButton onClick={()=>setShowPassword(!showPassword)} edge="end">
                  {showPassword?<VisibilityOff/>:<Visibility/>}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button variant="contained" color="primary" fullWidth type="submit" sx={{mb:2}}>Login</Button>
        <Button variant="outlined" color="primary" fullWidth href="/auth/google" sx={{mb:2}}>
          Sign in with Google
        </Button>
        <Typography variant="body2" color="text.secondary" align="center">
          <MuiLink href="/forgot-password">Forgot Password?</MuiLink>
        </Typography>
      </Box>
    </Container>
  );
}

export default LoginPage;
