// src/pages/SignUpPage.jsx
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button, InputAdornment, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Visibility, VisibilityOff, CheckCircleOutline, CancelOutlined } from '@mui/icons-material';
import API from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

function SignUpPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email:'', password:'', confirmPassword:'' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');

  const [passwordValid, setPasswordValid] = useState({
    length: false,
    uppercase: false,
    number: false,
    special: false,
    match: false
  });

  const passwordRules = [
    { name: 'At least 8 characters', key:'length' },
    { name: 'At least one uppercase letter', key:'uppercase' },
    { name: 'At least one number', key:'number' },
    { name: 'At least one special character', key:'special' },
    { name: 'Passwords match', key:'match' }
  ];

  const checkPassword = (pwd, confirm) => {
    const length = pwd.length >= 8;
    const uppercase = /[A-Z]/.test(pwd);
    const number = /[0-9]/.test(pwd);
    const special = /[^A-Za-z0-9]/.test(pwd);
    const match = pwd === confirm && pwd.length > 0;
    setPasswordValid({ length, uppercase, number, special, match });
  };

  useEffect(() => {
    checkPassword(formData.password, formData.confirmPassword);
  }, [formData.password, formData.confirmPassword]);

  const allConditionsMet = passwordValid.length && passwordValid.uppercase && passwordValid.number && passwordValid.special;

  const onChange = (e) => setFormData({...formData,[e.target.name]:e.target.value});

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!allConditionsMet) {
      setError('Password does not meet requirements.');
      return;
    }
    if (!passwordValid.match) {
      setError('Passwords do not match.');
      return;
    }
    try {
      const res = await API.post('/auth/register', formData);
      console.log('Signup success:', res.data);
      navigate('/login');
    } catch(err) {
      console.log('Signup error response:', err.response?.data);
      let errMsg = err.response?.data?.error || err.response?.data?.message || 'Sign up failed. Check if user already exists or your inputs are correct.';
      setError(errMsg);
    }
  };

  return (
    <Container sx={{ mt:8,maxWidth:'sm' }}>
      <Typography variant="h4" gutterBottom>Sign Up</Typography>
      {error && <Typography color="error" paragraph>{error}</Typography>}
      <Box component="form" onSubmit={onSubmit} sx={{maxWidth:400,mx:'auto'}}>
        <TextField
          name="email"
          label="Email"
          type="email"
          fullWidth
          sx={{mb:2}}
          value={formData.email}
          onChange={onChange}
          required
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword?'text':'password'}
          fullWidth
          sx={{mb:2}}
          value={formData.password}
          onChange={onChange}
          required
          error={!allConditionsMet && formData.password.length>0}
          InputProps={{
            style: {
              borderColor: allConditionsMet ? 'green' : (formData.password.length>0 ? 'red' : '')
            },
            endAdornment:(
              <InputAdornment position="end">
                <IconButton onClick={()=>setShowPassword(!showPassword)} edge="end">
                  {showPassword?<VisibilityOff/>:<Visibility/>}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <TextField
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword?'text':'password'}
          fullWidth
          sx={{mb:2}}
          value={formData.confirmPassword}
          onChange={onChange}
          required
          error={formData.confirmPassword.length>0 && !passwordValid.match}
          InputProps={{
            style: {
              borderColor: passwordValid.match ? 'green' : (formData.confirmPassword.length>0 ? 'red' : '')
            },
            endAdornment:(
              <InputAdornment position="end">
                <IconButton onClick={()=>setShowConfirmPassword(!showConfirmPassword)} edge="end">
                  {showConfirmPassword?<VisibilityOff/>:<Visibility/>}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <Typography variant="body2" paragraph color="text.secondary" sx={{fontWeight:'bold'}}>
          Password Requirements:
        </Typography>
        <List dense>
          {passwordRules.map((rule) => {
            const met = passwordValid[rule.key];
            return (
              <ListItem key={rule.key}>
                <ListItemIcon>
                  {met ? <CheckCircleOutline sx={{color:'green'}}/> : <CancelOutlined sx={{color:'red'}}/>}
                </ListItemIcon>
                <ListItemText primary={rule.name} primaryTypographyProps={{color:'text.secondary'}} />
              </ListItem>
            );
          })}
        </List>

        <Button variant="contained" color="primary" fullWidth type="submit" sx={{mb:2}}>Sign Up</Button>
        <Button variant="outlined" color="primary" fullWidth href="/auth/google">
          Sign Up with Google
        </Button>
      </Box>
    </Container>
  );
}

export default SignUpPage;
