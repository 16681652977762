// src/pages/CalendarPage.jsx
import React from 'react';
import { Container, Typography } from '@mui/material';
import Footer from '../components/Footer';

function CalendarPage() {
  return (
    <Container sx={{ mt:4 }}>
      <Typography variant="h3" gutterBottom>Event Calendar</Typography>
      <Typography variant="body1" paragraph>
        Explore upcoming events at a glance. Publicly, we present a simple listing of future gatherings. Attendees can join easily, and hosts can request approval to manage their own events more deeply.
      </Typography>
      <Typography variant="body1" paragraph>
        Without disclosing inner workings, the calendar simply helps you discover what’s happening. For more specialized host capabilities, you’ll need approval and possibly premium host tiers.
      </Typography>
      <Typography variant="body1" paragraph>
        Learn more on our <a href="/features">Features</a> or <a href="/pricing">Pricing</a> pages.
      </Typography>
    </Container>,
  <Footer />
  );
}

export default CalendarPage;
