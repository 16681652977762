// src/components/NavBar.jsx
import React from 'react';
import { AppBar, Toolbar, Typography, Box, Button, IconButton } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useContext } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

function NavBar() {
  const { auth } = useContext(AuthContext);
  const isLoggedIn = !!auth.token;
  const user = auth.user;
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    if (!isLoggedIn) {
      navigate('/');
      return;
    }
    navigate('/dashboard');
  };

  const linkStyle = (path) => {
    const isActive = location.pathname === path;
    return {
      mr:2,
      ml: isActive ? 4 : 2, // indent if active
      color: isActive ? 'primary.main' : 'inherit'
    };
  };

  if (!isLoggedIn) {
    return (
      <AppBar position="static" color="transparent" sx={{ boxShadow:'none', borderBottom:'1px solid #ddd' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow:1, cursor:'pointer' }} onClick={handleLogoClick}>
            CoHost
          </Typography>
          <Box>
            <Button component={Link} to="/" sx={linkStyle('/')}>Home</Button>
            <Button component={Link} to="/about" sx={linkStyle('/about')}>About</Button>
            <Button component={Link} to="/features" sx={linkStyle('/features')}>Features</Button>
            <Button component={Link} to="/pricing" sx={linkStyle('/pricing')}>Pricing</Button>
            <Button component={Link} to="/how-it-works" sx={linkStyle('/how-it-works')}>How It Works</Button>
            <Button component={Link} to="/signup" color="primary" variant="contained" sx={linkStyle('/signup')}>Sign Up</Button>
            <Button component={Link} to="/login" color="primary" variant="outlined" sx={linkStyle('/login')}>Login</Button>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }

  const isAdmin = user && user.role === 'admin';

  return (
    <AppBar position="static" color="transparent" sx={{ boxShadow:'none', borderBottom:'1px solid #ddd' }}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow:1, cursor:'pointer' }} onClick={handleLogoClick}>
          CoHost
        </Typography>
        <Box>
          <Button component={Link} to="/dashboard" sx={linkStyle('/dashboard')}>Dashboard</Button>
          <Button component={Link} to="/calendar" sx={linkStyle('/calendar')}>Calendar</Button>
          <IconButton component={Link} to="/profile" sx={{mr:2}}>
            <PersonIcon />
          </IconButton>
          <IconButton component={Link} to="/settings" sx={{mr:2}}>
            <SettingsIcon />
          </IconButton>
          {isAdmin && (
            <Button component={Link} to="/admin-dashboard" sx={linkStyle('/admin-dashboard')}>Admin Dashboard</Button>
          )}

          <Button component={Link} to="/logout" color="secondary" variant="contained" sx={linkStyle('/logout')}>Logout</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
