// src/pages/HomePage.jsx
import React from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardContent } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import Hero from '../components/Hero';

function HomePage() {
  const { auth } = useAuth();
  const isLoggedIn = !!auth.token;

  return (
    <div>
      <Hero
        title={isLoggedIn ? 'Welcome Back to CoHost' : 'Elevate Your Event Experience'}
        subtitle={isLoggedIn
          ? 'Explore upcoming events and enjoy a smoother attendance process. Consider host status if your needs grow.'
          : 'Discover events effortlessly, sign up easily, and enjoy a hassle-free experience from start to finish.'}
      />
      <Container sx={{ mt:8 }}>
        {!isLoggedIn ? (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              A New Way to Experience Events
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ maxWidth:700, mx:'auto' }}>
              CoHost keeps it simple. As an attendee, find events and join them without complexity. Over time, if you wish, request host status to access more tools.
            </Typography>

            <Typography variant="h5" align="center" gutterBottom sx={{ mt:6 }}>
              Simple, Intuitive, Inclusive
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ maxWidth:700, mx:'auto' }}>
              Start free as an attendee. No pressure—just a path from curious event-goer to engaged host if that suits you later.
            </Typography>

            <Box sx={{ textAlign:'center', mt:10, mb:10 }}>
              <Typography variant="h5" paragraph>
                Ready to Join?
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph sx={{ maxWidth:600, mx:'auto' }}>
                Sign up now to explore events. Check <a href="/about">About</a>, <a href="/features">Features</a>, <a href="/pricing">Pricing</a>, and <a href="/how-it-works">How It Works</a> for an overview.
              </Typography>
              <Button variant="outlined" color="primary" size="large" href="/signup">
                Sign Up Now
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Enhance Your Experience
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ maxWidth:700, mx:'auto' }}>
              Logged in? Check the calendar or your profile. If you’re interested in hosting, apply for host status and discover advanced tools down the line.
            </Typography>
          </>
        )}
      </Container>

      <Container sx={{ mt:10, mb:10 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Key Highlights
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ maxWidth:700, mx:'auto' }}>
          CoHost’s public features focus on simplicity: easy discovery, basic attendance, and room to grow at your pace.
        </Typography>
        <Grid container spacing={4} sx={{ mt:4 }}>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Effortless Discovery</Typography>
                <Typography variant="body2">
                  Find events easily without complexity—just pick and attend.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Basic Attendance</Typography>
                <Typography variant="body2">
                  Smooth check-ins and no intricate steps, perfect for newcomers.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{height:'100%'}}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Room to Grow</Typography>
                <Typography variant="body2">
                  Start as attendee and evolve into a host role only if and when you want.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
     
    </div>
  );
}

export default HomePage;
