// src/pages/HowItWorks.jsx
import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// A stable event image from Unsplash:
const heroImageUrl = 'https://images.unsplash.com/photo-1530520805182-fba3e084e00d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1950&q=80';

function HowItWorks() {
  const [enteredPassword, setEnteredPassword] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const correctPassword = 'HostSoHard';

  const handleLogin = () => {
    if (enteredPassword === correctPassword) {
      setAccessGranted(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  if (!accessGranted) {
    return (
      <Container sx={{ mt: 10, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Restricted Access
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Please enter the password to view detailed information.
        </Typography>
        <Box sx={{ maxWidth: 400, mx: 'auto' }}>
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="Password"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={()=>setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
            Enter
          </Button>
        </Box>
      </Container>
    );
  }

  // Once Access is Granted, show a hero section for internal details and then two main sections:
  // 1. Detailed Overview (as before)
  // 2. Timeline section subdivided by dev paths

  return (
    <div>
      <Box
        sx={{
          position:'relative',
          height:{ xs:'300px', md:'400px' },
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          color:'#fff',
          backgroundImage:`url(${heroImageUrl})`,
          backgroundSize:'cover',
          backgroundPosition:'center',
          '&:before': {
            content:'""',
            position:'absolute',
            top:0,left:0,right:0,bottom:0,
            background:'linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.3), rgba(0,0,0,0.6))',
            zIndex:1,
          },
        }}
      >
        <Container sx={{ position:'relative', zIndex:2, textAlign:'center' }}>
          <Typography variant="h2" sx={{ fontWeight:'bold', fontSize:{xs:'2rem',md:'3rem'}, mb:2 }}>
            Internal Operational Blueprint
          </Typography>
          <Typography variant="h6" sx={{maxWidth:600,mx:'auto'}}>
            Deep dive into CoHost’s full capabilities & structured dev plan
          </Typography>
        </Container>
      </Box>

      <Container sx={{ mt:8 }}>
        <Typography variant="h3" gutterBottom>
          Full Capabilities Overview
        </Typography>
        <Typography variant="body1" paragraph color="text.secondary">
          This section provides a comprehensive look at CoHost’s hidden features, strategic growth, and future roadmap—beyond what public pages reveal.
        </Typography>

        {/* Detailed Overview (First main section) */}
        <Typography variant="h5" gutterBottom sx={{mt:4}}>
          Detailed Overview
        </Typography>
        <Typography variant="body1" paragraph>
          1. Secure ID Verification & Reputation: Initially OCR-based, later integrated with external databases, eventually offering complex trust scoring and even optional biometrics if compliant.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Promoter & Venue Tools: Start simple with manual tagging, evolve into discovery marketplace, ML-driven recommendations, and CRM integrations.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Monetization & Pricing: Begin with basic free tiers for attendees and private hosts, add premium tiers with advanced analytics and promoter tools, layer dynamic pricing models later.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Privacy & Compliance: Early stages focus on basic opt-outs, mid-term on anonymization and user consent dashboards, long-term fully granular controls.
        </Typography>
        <Typography variant="body1" paragraph>
          5. Integrations & Partnerships: Gradual expansion from small orgs and universities to major ticketing and large-scale events, eventually forming an ecosystem standard.
        </Typography>
        <Typography variant="body1" paragraph>
          6. Analytics & Predictive Insights: From basic metrics to ML predictions, attendee interest modeling, and recommended event structures based on historical data.
        </Typography>

        {/* Timeline Section (Second main section) */}
        <Typography variant="h5" gutterBottom sx={{mt:8}}>
          Timeline & Dev Paths
        </Typography>
        <Typography variant="body1" paragraph color="text.secondary">
          Below is a timeline outlining phased rollouts, subdivided by development paths (ID Verification, Promoter Tools, Monetization, Privacy, Integrations, Analytics).
        </Typography>

        {/* Example Timeline using headings and paragraphs */}
        <Box sx={{ mt:4 }}>
          <Typography variant="h6" gutterBottom>
            Q1-Q2:
          </Typography>
          <Typography variant="body1" paragraph>
            <b>ID Verification:</b> Launch OCR scanning, basic show/no-show scoring.<br/>
            <b>Promoter Tools:</b> Manual tagging, basic campaigns.<br/>
            <b>Monetization:</b> Free tiers for attendee/private host, simple per-attendee fee for discovered users in low-margin events.<br/>
            <b>Privacy:</b> Basic opt-outs, compliance with standard regs.<br/>
            <b>Integrations:</b> Start talks with small local orgs.<br/>
            <b>Analytics:</b> Basic attendance counts, repeat visitors.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Q3-Q4:
          </Typography>
          <Typography variant="body1" paragraph>
            <b>ID Verification:</b> Integrate select external databases for trust scoring.<br/>
            <b>Promoter Tools:</b> Discovery marketplace MVP, simple segmentation filters.<br/>
            <b>Monetization:</b> Introduce first premium host tier with advanced analytics.<br/>
            <b>Privacy:</b> Add anonymization after certain retention periods.<br/>
            <b>Integrations:</b> Initial partnerships with a ticketing platform, sync ticket sales + CoHost check-ins.<br/>
            <b>Analytics:</b> Add segmentation by demographics and basic trend reports.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Year 2:
          </Typography>
          <Typography variant="body1" paragraph>
            <b>ID Verification:</b> Possibly pilot biometric verification in compliant regions.<br/>
            <b>Promoter Tools:</b> ML-driven recommendations for target audiences, advanced campaign management.<br/>
            <b>Monetization:</b> Dynamic pricing engine, additional premium tiers, enterprise packages.<br/>
            <b>Privacy:</b> Full user control dashboard, granular consent frameworks.<br/>
            <b>Integrations:</b> Expand to major festivals and conferences, possible white-label solutions.<br/>
            <b>Analytics:</b> Predictive modeling: suggest event start times, staffing, invite lists.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Beyond Year 2:
          </Typography>
          <Typography variant="body1" paragraph>
            <b>ID Verification:</b> Refine trust models, integrate multiple ID sources globally.<br/>
            <b>Promoter Tools:</b> Fully mature ecosystem of APIs, CRM integrations, automated personalized marketing.<br/>
            <b>Monetization:</b> Potential revenue-share models, contextual pricing based on event popularity and attendee interest.<br/>
            <b>Privacy:</b> Possibly pioneering industry standards for event attendee data rights.<br/>
            <b>Integrations:</b> Become an industry standard for event check-ins and analytics worldwide.<br/>
            <b>Analytics:</b> Advanced ML continually refines predictions, suggests event line-ups, content curation, and personalized attendee recommendations.
          </Typography>
        </Box>

        <Typography variant="body1" paragraph sx={{mt:4,mb:10}} color="text.secondary">
          This dual-structured plan—detailed overview plus a timeline—ensures a clear internal roadmap, guiding CoHost’s transformation from a simple attendee platform to a comprehensive event ecosystem leader.
        </Typography>
      </Container>
    </div>
  );
}

export default HowItWorks;
