// src/pages/Dashboard.jsx
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Container, Typography, Box, Button } from '@mui/material';
import LoadingSpinner from '../components/LoadingSpinner';
import API from '../utils/axiosInstance';

const Dashboard = () => {
  const { auth, login } = useAuth();
  const user = auth.user;
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [logs, setLogs] = useState([]);
  const [hostRequested, setHostRequested] = useState(false);

  // Fetch events and logs on load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventResponse = await API.get('/events');
        const logResponse = await API.get('/logs');
        setEvents(eventResponse.data);
        setLogs(logResponse.data);
      } catch (err) {
        console.error('Dashboard fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle host request logic
  useEffect(() => {
    if (user && user.role === 'host' && user.status === 'pending') {
      setHostRequested(true);
    } else {
      setHostRequested(false);
    }
  }, [user]);

  // Apply to be a host
  const applyForHost = async () => {
    try {
      await API.post('/auth/request-host');
      const meRes = await API.get('/auth/me');
      login(meRes.data.data, auth.token);
      setHostRequested(true);
    } catch (err) {
      console.error('Request host error:', err);
    }
  };

  // Cancel host application
  const cancelHostRequest = async () => {
    try {
      await API.post('/auth/cancel-host');
      const meRes = await API.get('/auth/me');
      login(meRes.data.data, auth.token);
      setHostRequested(false);
    } catch (err) {
      console.error('Cancel host error:', err);
    }
  };

  if (loading) return <LoadingSpinner />;

  if (!user) {
    return (
      <Container>
        <Typography variant="h4" mt={4}>Dashboard</Typography>
        <Typography variant="body1">You are not logged in. Please <a href="/login">Login</a> or <a href="/signup">Sign Up</a>.</Typography>
      </Container>
    );
  }

  const { role, status, name, email } = user;
  const isApproved = status === 'approved';
  const isAttendee = role === 'attendee';

  return (
    <Container>
      <Typography variant="h4" gutterBottom mt={4}>Dashboard</Typography>
      <Typography variant="body1" paragraph>
        Welcome, {name || email}!<br />
        Role: {role.toUpperCase()}<br />
        Status: {status.charAt(0).toUpperCase() + status.slice(1)}
      </Typography>

      {isAttendee && isApproved && (
        <Box sx={{ mt: 2 }}>
          {!hostRequested ? (
            <Button variant="contained" color="primary" onClick={applyForHost}>
              Apply to be a Host
            </Button>
          ) : (
            <Box>
              <Button variant="contained" color="secondary" disabled sx={{ mr: 2 }}>Requested</Button>
              <Button variant="outlined" color="primary" onClick={cancelHostRequest}>Cancel Application</Button>
            </Box>
          )}
        </Box>
      )}

      <section>
        <Typography variant="h5" mt={4}>Events</Typography>
        {events.length ? (
          <ul>
            {events.map(event => (
              <li key={event.id}>{event.name}</li>
            ))}
          </ul>
        ) : (
          <Typography>No events available.</Typography>
        )}
      </section>

      <section>
        <Typography variant="h5" mt={4}>User Logs</Typography>
        {logs.length ? (
          <ul>
            {logs.map(log => (
              <li key={log.id}>{log.action}</li>
            ))}
          </ul>
        ) : (
          <Typography>No logs available.</Typography>
        )}
      </section>
    </Container>
  );
};

export default Dashboard;
