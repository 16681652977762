// src/components/Footer.jsx
import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

function Footer() {
  return (
    <Box sx={{ mt:4, py:2, textAlign:'center', borderTop:'1px solid #ddd', position:'relative' }}>
      <Typography variant="body2" paragraph color="text.secondary">
        © {new Date().getFullYear()} CoHost. All rights reserved.
      </Typography>
      <Typography variant="body2" color="text.secondary" paragraph>
        <Link href="mailto:cohostapp@gmail.com" underline="hover">Contact Us</Link> | 
        <Link href="https://www.linkedin.com/company/thecohost/" underline="hover" sx={{ml:1}}>LinkedIn</Link> | 
        <Link href="https://www.instagram.com/cohostapp/" underline="hover" sx={{ml:1}}>Instagram</Link>
      </Typography>
      <Box sx={{position:'absolute', bottom:16, right:16}}>
        <IconButton href="/admin-login">
          <LockIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Footer;
